import { useMemo } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import queryString, { ParsedQuery } from 'query-string';
import { mobile } from 'styles/responsive';
import Template from 'components/templates/MainTemplate';
import PostList, { PostType } from 'components/organisms/home/PostList';
import { PostListItemType } from 'types/PostItem.types';
import MobileInfo from 'components/organisms/home/MobileInfo';
import Header from 'components/organisms/header/Header';
import MainContent from 'components/molecules/container/MainContent';
import TagList, { TagListType } from 'components/organisms/home/TagList';
import Footer from 'components/organisms/footer/Footer';

interface IndexPageType {
  location: {
    search: string;
  };
  data: {
    allMarkdownRemark: {
      edges: PostListItemType[];
      group: [
        {
          fieldValue: string;
          totalCount: number;
        },
      ];
    };
  };
}

const Container = styled.div`
  position: relative;
  margin: 30px 30px 30px 0;
  padding: 50px 0;
  border: var(--main-content-border);
  border-radius: 10px;
  background: var(--main-content-bg);
  box-shadow: var(--main-content-shadow);

  @media (max-width: ${mobile}) {
    margin: 20px;
    padding: 30px 0;
  }
`;

export default function IndexPage({
  location,
  data: {
    allMarkdownRemark: { edges },
  },
}: IndexPageType) {
  const { search } = location;
  const parsed: ParsedQuery<string> = queryString.parse(search);
  const selectedTag: string =
    typeof parsed.tag !== 'string' || !parsed.tag ? 'All' : parsed.tag;

  const tagList = useMemo(
    () =>
      edges.reduce(
        (
          list: TagListType['tagList'],
          {
            node: {
              frontmatter: { tags },
            },
          }: PostType,
        ) => {
          tags.forEach((tag) => {
            if (list[tag] === undefined) list[tag] = 1;
            else list[tag]++;
          });

          list['All']++;

          return list;
        },
        { All: 0 },
      ),
    [],
  );

  return (
    <Template>
      <MobileInfo />
      <Container>
        <Header />
        <MainContent>
          <TagList selectedTag={selectedTag} tagList={tagList} />
          <PostList selectedTag={selectedTag} posts={edges} />
          <Footer />
        </MainContent>
      </Container>
    </Template>
  );
}

export const getPostList = graphql`
  query getPostList {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            summary
            date(formatString: "MMM DD, YYYY")
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 750, height: 400, breakpoints: [750])
              }
            }
          }
        }
      }
    }
  }
`;
