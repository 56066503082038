import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { mobile } from 'styles/responsive';
import useClickOutside from 'hooks/useClickOutside';
import TagItem from 'components/atoms/tag/TagItem';
import DropdownButton from 'components/atoms/button/DropdownButton';
config.autoAddCss = false;

export interface TagListType {
  selectedTag: string;
  tagList: {
    [key: string]: number;
  };
}

const Title = styled.div`
  display: flex;
  //line-height: 50px;
  align-items: center;
`;

const TagText = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--point-text-color);
  font-size: var(--fs-xl);
  line-height: 1.5;

  @media (max-width: ${mobile}) {
    font-size: 20px;
  }
`;

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 24px;

  &.acitve {
    display: block;
  }
  &.inactive {
    display: none;
  }

  @media (max-width: ${mobile}) {
    margin: 0;
    padding-left: 20px;
  }
`;

export default function TagList({ selectedTag, tagList }: TagListType) {
  const { isOpened, outsideRef, handleClickElement } = useClickOutside(
    false,
    true,
  );

  const { All, ...restTags } = tagList;

  return (
    <div ref={outsideRef}>
      <Title>
        <TagText>
          <FontAwesomeIcon icon={faTag} />
          Tags
          <DropdownButton handleButtonClick={handleClickElement} />
        </TagText>
      </Title>
      <TagListWrapper className={`${isOpened ? 'active' : 'inactive'}`}>
        <TagItem
          key={'All'}
          name={'All'}
          selectedTag={selectedTag}
          count={All}
        />
        {Object.entries(restTags)
          .sort()
          .map(([name, count]) => (
            <TagItem
              key={name}
              name={name}
              selectedTag={selectedTag}
              count={count}
            />
          ))}
      </TagListWrapper>
    </div>
  );
}
