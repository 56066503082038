import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyLinkType } from 'types/Link.types';
import { mobile } from 'styles/responsive';

interface TagItemType {
  selectedTag: string;
  name: string;
  count: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tag = styled(({ ...props }: GatsbyLinkType) => <Link {...props} />)`
  margin: 16px 16px 0 0;
  padding: 8px 16px;
  border: ${(props) =>
    props.active
      ? '2px solid var(--point-color)'
      : 'var(--main-content-border)'};
  border-radius: 16px;
  background: var(--point-bg);
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  color: ${(props) =>
    props.active ? 'var(--point-color)' : 'var(--text-color)'};
  font-size: var(--fs-l);
  cursor: pointer;

  &:hover {
    color: var(--point-color);
  }
  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${mobile}) {
    margin-top: 10px;
    margin-right: 10px;
    font-size: 12px;
  }
`;

export default function TagItem({ selectedTag, name, count }: TagItemType) {
  return (
    <Tag to={`/?tag=${name}`} active={name === selectedTag ? 1 : 0}>
      {name.replace('-', ' ')} ({count})
    </Tag>
  );
}
