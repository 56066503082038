import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { laptop, mobile } from 'styles/responsive';
import { PostFrontmatterType } from 'types/PostItem.types';
import PostItemContainer from '../container/PostItemContainer';
import PostTagList from '../../organisms/post/PostTagList';
import PostTitle from 'components/atoms/Text/PostTitle';
import PostSummary from 'components/atoms/Text/PostSummary';
import PostDate from 'components/atoms/Text/PostDate';

interface VerPostItemType extends PostFrontmatterType {
  link: string;
}

const ThumbnailImage = styled(GatsbyImage)`
  @media (max-width: ${mobile}) {
    height: 150px;
  }
`;

const PostItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  width: 100%;
  padding: 24px;

  @media (max-width: ${laptop}) {
    gap: 12px;
    padding: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default function VerPostItem({
  title,
  date,
  tags,
  categories,
  summary,
  thumbnail: {
    childImageSharp: { gatsbyImageData },
  },
  link,
}: VerPostItemType) {
  return (
    <PostItemContainer link={link} direction={`vertical`}>
      <ThumbnailImage image={gatsbyImageData} alt="Post Thumbnail Image" />
      <PostItemContent>
        <PostTagList tags={tags} direction={`vertical`} />
        <Content>
          <PostTitle
            category={categories}
            title={title}
            direction={`vertical`}
          />
          <PostSummary summary={summary} />
        </Content>
        <PostDate icon={faCalendar} date={date} />
      </PostItemContent>
    </PostItemContainer>
  );
}
