import styled from '@emotion/styled';
import { mobile, tabletL } from 'styles/responsive';
import Profile from '../../molecules/info/Profile';
import Status from 'components/atoms/login/Status';
import NavIcon from '../sidebar/NavIconList';

const MobileInfoContainer = styled.nav`
  display: none;

  @media (max-width: ${tabletL}) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 20px;
  }

  @media (max-width: ${mobile}) {
    min-height: 49px;
  }
`;

export default function MobileInfo() {
  return (
    <MobileInfoContainer>
      <Profile />
      <Status />
      <NavIcon />
    </MobileInfoContainer>
  );
}
