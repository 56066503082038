import styled from '@emotion/styled';
import { mobile, tabletS } from 'styles/responsive';
import { PostListItemType } from 'types/PostItem.types';
import useInfiniteScroll, {
  useInfiniteScrollType,
} from 'hooks/useInfiniteScroll';
import ContentContainer from 'components/molecules/container/ContentWrapper';
import ContentTitle from 'components/atoms/Text/ContentTitle';
import VerPostItem from 'components/molecules/items/VerPostItem';

export interface PostType {
  node: {
    id: string;
    frontmatter: {
      title: string;
      summary: string;
      date: string;
      tags: string[];
      categories: string;
      thumbnail: {
        publicURL: string;
      };
    };
  };
}

interface PostListType {
  selectedTag: string;
  posts: PostListItemType[];
}

const PostListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @media (max-width: ${tabletS}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr;
  }
`;

export default function PostList({ selectedTag, posts }: PostListType) {
  const { containerRef, postList }: useInfiniteScrollType = useInfiniteScroll(
    selectedTag,
    posts,
  );

  return (
    <ContentContainer>
      <ContentTitle title={`Posts`} />
      <PostListWrapper ref={containerRef}>
        {postList.map(
          ({
            node: {
              id,
              fields: { slug },
              frontmatter,
            },
          }: PostListItemType) => (
            <VerPostItem {...frontmatter} link={slug} key={id} />
          ),
        )}
      </PostListWrapper>
    </ContentContainer>
  );
}
